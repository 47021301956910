html {
  font-size: 62.5%;
}

body {
  height: 100vh;

  /* background: #136a8a;
  background: -webkit-linear-gradient(
    to right,
    #267871,
    #136a8a
  ); 
  background: linear-gradient(
    to right,
    #267871,
    #136a8a
  ); 

  color: #effe92; */
  background-size: cover;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('assets/images/bg.jpg');
}

.pages {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem
}

@media only screen and (max-width: 768px) {
  .pages {
    font-size: 2.5rem;
  }
}
