@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 62.5%;
}

body {
  height: 100vh;

  /* background: #136a8a;
  background: -webkit-linear-gradient(
    to right,
    #267871,
    #136a8a
  ); 
  background: linear-gradient(
    to right,
    #267871,
    #136a8a
  ); 

  color: #effe92; */
  background-size: cover;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  background-image: url(/static/media/bg.60e1cdb6.jpg);
}

.pages {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem
}

@media only screen and (max-width: 768px) {
  .pages {
    font-size: 2.5rem;
  }
}


body{
  font-family: 'Open Sans', sans-serif !important;
  /* height:100% !important; */
  background-size:cover;
  background-repeat:no-repeat;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Roboto', sans-serif !important;
}

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 42rem;
    padding: 2.5rem;
    box-sizing: border-box;
    background-color: rgba(10,42,65, 0.6);
    border-radius: 5px;
  }

  .login-text {
    font-size: 14px;
    color: #fff;
    font-family: 'Roboto';
    font-weight: 500;
}
  
  .box h2 {
    margin: 0 0 1.875rem;
    padding: 0;
    color: #fff;
    text-align: center;
  }
  
  .box .inputBox {
    position: relative;
  }
  
  .box .inputBox input {
    width: 100%;
    padding: 0.625rem 0;
    font-size: 1rem;
    color: #fff;
    letter-spacing: 0.062rem;
    margin-bottom: 1.875rem;
    border: none;
    border-bottom: 0.065rem solid #fff;
    outline: none;
    background: transparent;
  }
  
  .box .inputBox label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.625rem 0;
    font-size: 14px;
    color: #fff;
    pointer-events: none;
    transition: 0.5s;
  }
  
  .box .inputBox input:focus ~ label,
  .box .inputBox input:valid ~ label,
  .box .inputBox input:not([value=""]) ~ label {
    top: -1.125rem;
    left: 0;
    color: #03a9f4;
    font-size: 0.75rem;
  }
  
  .box input[type="submit"] {
    border: none;
    outline: none;
    color: #fff;
    background-color: #03a9f4;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    border-radius: 0.312rem;
    font-size: 1rem;
  }
  
  .box input[type="submit"]:hover {
    background-color: #1cb1f5;
  }

  .center-text{
      color: #fff;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}
/* .jdovsQ {
    background-color: #204E70;
} */
/* .fSJQOL{
    background-color:#fff !important;
    position:static !important;
} */

/* .jgNvlw, .gFriBE{
    background-image: none !important;
    background-color:#204E70;
} */
.custom-header-container {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.pages h2{
  font-size:47px;
}

p {
  color: #FFFFFF;
  text-shadow: 0px 3px 6px #0000005e !important;
  opacity: 0.83;
  font-size: 16px !important;
  font-weight: normal;
}
.start-button {
  background-color: #3E98BB !important;
  padding: 6px 15px;
  width: 100% !important;
  display: block;
  max-width: 175px;
  margin-right: auto;
  margin-left:auto;
  margin-top:30px;
}
.custom-sec-mw p {
  margin: 15px;
}

.pages{
  position:static;
  transform:none;
}
.docs-lists .card {
  display: flex !important;
  min-height: 140px;
  justify-content: center;
  align-items: center;
  font-size:16px;
  margin: 0 !important;
    padding: 5px !important;
    cursor: pointer;
    text-align:center;
    /* box-shadow: 1px 1px 0px #2576a782,
     */
    box-shadow:  5px 5px rgba(57,129,172, 0.5);    
    border-bottom:5px solid transparent;
    transition: all 0.5s;
    background-image:linear-gradient(#014F89, #2F78A6);
    color:#fff;
}

.docs-lists .card svg {
  height: 30px !important;
  margin-bottom:10px;
}
.docs-lists .row .col-3{
  padding:20px;
}
.page-heading {
  display: flex;
  width: 100%;
  font-size: 32px;
  font-weight: 500;
  font-family: 'Roboto';
  padding: 60px 0 40px 0px;
  color:#fff;
}
.logo img {
  width: 150px;
}
/* .gBMIdc{
  margin-top:24px !important;
} */
/* .fSJQOL{
  height: 5rem !important;
  width: 5rem !important;
} */
/* .kpuxac{
  margin-top:24px !important;
} */

/* svg.back-button {
  height: 30px;
  margin: 60px 0px 40px 0px;
  width: 30px !important;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 100%;
  height: 30px;
} */
.back-button {
  height: 20px;
  position: relative;
  top: 2px;
  margin: 60px 0px 40px 0px;

}

.svg-inline--fa {
  height: 28px;
  /* border: 1px solid #fff;
  padding: 4px; */
}

a{
  text-decoration: none;
}
/* .docs-lists .card{
  border-bottom:5px solid transparent;
  transition: all 0.5s;
  background-image:linear-gradient(#014F89, #2F78A6);
  color:#fff;
} */
.docs-lists .card:hover{
  border-bottom:5px solid #fff;
}
.pagination a.page-link {
  font-size: 14px;
}
.pagination a.page-link {
  font-size: 14px;
  border: 0;
  background-color: transparent;
  color: #fff;
}
.pagination{
  padding-bottom:20px;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: auto;
  right: 60px;
}
.pages{
  padding:0 20px;
  height:100%;
}
.header-fullw {
  background-color: rgba(0,0,0,0.3);
  padding: 30px 0;
}
.start-button .back-button{
  border:none;
  margin:0;
}


#root {
  height: 100%;
}
html {
  height: 100%;
}

.custom-sec-mw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;
  /* padding: 150px 0 0 0; */
  padding: 70px 0 70px 0;
}


.print-btn {
  width: auto;
}
.print-btn a {
  padding: 4px 22px;
  font-size: 14px;
  letter-spacing: 1px;
}
.doc-view-main {
  padding-bottom: 25px;
}
.start-button span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}
.pages .box h2{
  font-size:20px;
}
.box button {
  margin-top: 20px;
}
.forgot-pwd {
  float: right;
  color: #fff;
  font-size: 14px;
}

.docs-lists .content-lists {
  /* height: 390px; */
  height: 348px;
  overflow-y: auto;
}

.logout-btn {
  float: right;
  position: relative;
  right: 89px;
  bottom: 8px;
}
.spinner3 .loader .loader-parent .circle{
  top: 265% !important;
}

.pagination li a svg {
  width: 8px !important;
  font-size: 12px !important;
}
.pagination a.page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  color:#ccc;
}
.pagination li.active a.page-link {
  color:#fff;
  background-color:#3c83ae;
}
.box button.forgot-pwd {
  margin-top: 0;
}
.home-content p:nth-child(2) {
  font-size: 38px !important;
  font-weight: bold;
  color: #fff !important;
}
.home-content p.MsoNormal {
  margin-bottom: 0;
  margin-top: 0;
}
.home-content p span:empty{
  display: none;
}



.contact-ItemLink{
  display: inline-block;
  font-size: 3rem;
  font-weight: 300;
  text-decoration: none;
  color: #B6EDC8;
  padding: 1rem 2rem;
  background-image: linear-gradient(120deg,transparent 0%,
    transparent 50%,
    #fff 50%
  );
  background-size: 240%;
  transition: all 0.4s;
}
  
.contact-ItemLink:hover{
  background-position: 100%;
  color: #115b4c;
  transform: translateX(1rem);
}

/* new navigation css */

.custom-header-container .navbar-collapse{
  flex-grow:initial;
}
.custom-header-container .navbar-nav .nav-link {
  color: #fff;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: transparent;
  border-radius: 0;
}

.custom-header-container .navbar-nav .nav-link.active {
  color: #f1f1f1;
  background-color: #132f4a;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.custom-header-container .navbar-toggler-icon {
  border-top: 1px solid #000;
  display: block;
  line-height: normal;
  height: auto;
  margin-bottom: 5px;
  margin-top: 5px;
}
.custom-header-container .navbar-toggler {
  background-color: #fff;
  border: 1px solid #fff;
}


.backButtonNew svg {
  border: 1px solid #ccc;
  border-radius: 100%;
  width: 24px !important;
  height: 24px !important;
  padding: 4px;
}
.backButtonNew {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.backButtonNew label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width:auto;
}
.backButtonNew label span {
  margin-left: 10px;
}




/* minimum device media */

@media only screen and (min-width:992px){
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (min-width:768px){
  .home-content {
    max-width: 80%;
    margin: 0 auto;
}

.home-content p:nth-child(2) {
  font-size: 35px !important;  
}

}

@media only screen and (max-width:991px){  
  .custom-header-container .navbar-nav{
    background-color: #255a7c;
    padding: 20px;
  }
  .custom-header-container .navbar-nav .nav-link.active{
  padding-left: 15px;
  padding-right: 15px;
  }

  .custom-header-container .navbar-nav .nav-link{
    text-align: center;
  }

  .custom-header-container .navbar-nav li.nav-item {
    width: auto;
    max-width: 50%;
    margin: auto;
    padding:10px 0;
}

.custom-header-container .navbar-collapse {
  flex-grow: initial;
  position: absolute;
  top: 70px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
}

}


@media only screen and (max-width:767px){
  .docs-lists .row .col-3 {
    padding: 20px;
    flex: 0 0 50%;
    width: 100%;
}
.docs-lists .card{
  padding: 0 5px !important;
    text-align: center;
}
.pages h2 {
  font-size: 28px;
}
p{
  font-size: 14px !important;
}
.pages{
  padding:0;
}
.custom-header-container{
  padding-left: 15px;
    padding-right: 15px;
}
.back-button{
  margin-left:15px;
  cursor: pointer;
}

.spinner3 .loader .loader-parent .circle{
  top:50% !important;
}
.login-box{
  position: static;
    transform: none;
    width: 100%;
    margin-top: 30%;
  }
  .page-heading{
    padding:20px 0 20px 0px;
  }
  html{
    height:auto;
  }

  .home-content p:nth-child(2) {
    font-size: 35px !important;  
  }

  .doc-view-main{
    height: 370px !important;
  }

}


.vimeo{
  height: 500px;
  width: 100%;
  margin: 20px;
}

/* iframe{
  width: 100%;
  height: 100%;
  margin: 20px;
} */
.ytVideo {
  height: 500px !important;
}

@media only screen and (max-width:360px){

.page-heading{
  padding-top:20px;
  padding-bottom:20px;
}
.docs-lists .content-lists{
  height:290px;
}
.home-content{
  padding-bottom:20px;
}

.home-content p:nth-child(2) {
  font-size: 35px !important;  
}

}



